import { useState } from "react";
import { Form } from "react-bootstrap";
import config from "../config";
import React from 'react';
import DataTable from 'react-data-table-component';


  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


  import Dropzone from 'react-dropzone'

  
  const columns = [
      {
          name: 'Uploaded Date',
          selector: row => row.created,
      },
      {
          name: 'File Name',
          selector: row => <a href={"https://api.bagdiken.com/abstract/download/"+row.path+"/"} rel="noreferrer" target="_blank">{row.name}</a>,
      },
      {
          name: 'Upload IP',
          selector: row => row.ip,
      },
  ];
export default function UploadPage() {


    const [data,setData] = useState([]);
    const [formData] = useState({});
  
    

  React.useEffect(function(){
     
    config.apiClient
    .get('myPapers/')
    .then((inResponse) => {

        setData(inResponse.data.list);

    });

  },[])

   var upload = (file) => {
    console.log(file);
    
    toast.dismiss();
    toast.loading ("Loading",{
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    
    
    let data = new FormData();
    data.append('file', file[0]);



    config.apiClient
    .post('upload/', data)
    .then((response) => {
      
      toast.dismiss();
      
      
      if(response.data.status){
        

        
        config.apiClient
        .get('myPapers/')
        .then((inResponse) => {

            setData(inResponse.data.list);

        });


        response.data.msg.forEach(row => {
          toast.success(row, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });  
        });

   
        

      }else{
    
        

          toast.error(response.data.msg.join(", "), {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          enableHtml: true
          });

        

      }
      
    });



    console.log(formData);

    
}
   
  return (
    <div>
   
   
      <Form>
        <div className="row justify-content-md-center">
            


          <div className="col-offset-3 col-lg-6 col-sm-12">
            
           
           
<div className="row">
             <div className="col-12 dropZoneContainer">

      <Dropzone  onDrop={acceptedFiles => upload(acceptedFiles)}>
  {({getRootProps, getInputProps}) => (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag and drop your papers here, or click to select papers</p>
      </div>
    </section>
  )}
</Dropzone>



             

              </div>
            </div>





          <div className="row">
             <div className="col-12">

              
              <DataTable
            columns={columns}
            data={data}
        />
        
        </div>
        
        </div>


            
            
          </div>


        </div>
      </Form>








      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>




    </div>
  );
}
