import { useState } from "react";
import { Button, Form, Nav } from "react-bootstrap";
//import { Form } from "react-router-dom";
import config from "../config";
import React from 'react';
import DataTable from 'react-data-table-component';


  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


  import Dropzone, {useDropzone} from 'react-dropzone'
import { Navigate, redirect } from "react-router";



export default function LogoutPage() {


    
    

  React.useEffect(function(){
     
   
    
    config.apiClient
    .post('logout/')
    .then((response) => {
        localStorage.clear();
        
        window.history.pushState(null, null, "login")
        window.location.reload(false);
        });

  },[])
    
  



   
  


    
   
  return (
    <></>
  );
}
