import { useState } from "react";
import { Button, Form } from "react-bootstrap";
//import { Form } from "react-router-dom";
import config from "../config";
import React from 'react';


  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router";



export default function ActivationPage() {


    const [statusCode,setStatusCode] = useState("default");
    const [statusText,setStatusText] = useState("We are checking your activation token");
  const [isFetching,setIsFetching] = useState(false);



  
    let { selector, token } = useParams();


  React.useEffect(function(){
    console.log(selector);

    config.apiClient
    .get('activate/'+selector+"/"+token+"/", )
    .then((response) => {
      

        if(response.data.status){

            
    setStatusCode("text-success");


       // window.history.pushState(null, null, "upload");
        
       // window.location.reload(false);

       setTimeout(function(){
              
        window.history.pushState(null, null, "/login")
        window.location.reload(false);

            },3000)
       

      }else{
    
        setStatusCode("text-danger");
      }
      
      document.getElementsByClassName('lds-ring')[0].style.display = "none";

    setStatusText(response.data.msg[0]);
      
    });



  },[])
  


  
   
  return (
    <div>
   
   
        <div className="row justify-content-md-center">
            



        <div className="row">
                    
            <div className="activation_loading">
                    
            <div className="lds-ring"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            
            <br />
            <br />
            <br />
            <h2 className={statusCode}>{statusText}</h2>
            </div>
            

          </div>


        </div>
        








      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>




    </div>
  );
}
