import { useState } from "react";
import { Button, Form } from "react-bootstrap";
//import { Form } from "react-router-dom";
import config from "../config";
import React from 'react';


  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router";



export default function ResendPage() {


    const [statusCode,setStatusCode] = useState("default");
    const [statusText,setStatusText] = useState("Please enter your email adress to resend activation mail.");
  const [isFetching,setIsFetching] = useState(false);

  const [formData,setFormData] = useState({});
  

  var handleChange = (e) => {
    var tmp = {};
    console.log(e);
    console.log(config.baseURL);

    for (const [key, value] of Object.entries(formData)) {
      tmp[key] = value;
    }
    if(
      e.target.id=="at_campus" || 
      e.target.id=="present_paper"
      ){
      e.target.value = e.target.checked;
    }

    
      tmp[e.target.id] = e.target.value;
      

    setFormData(tmp);
    console.log(tmp);



  
   
    //this.setState({[e.data.target]: e.data.value})
   }

  
   var onSubmit = () => {
    setIsFetching(true);

    
    setStatusCode("text-default");
    
    setStatusText("please wait");
    
    //api.defaults.headers.post[''] = '*';
    config.apiClient
    .post('resend/', formData)
    .then((response) => {
      
      
      
      if(response.data.status){
        
       
    setStatusCode("text-success");
    setStatusText(response.data.msg[0]);
        

      }else{
    
        

        setIsFetching(false);
       
    setStatusCode("text-danger");
    setStatusText(response.data.msg[0]);
        
    setIsFetching(false);

      }
      
    });


    

    
}




  return (
    <div>
   
   
        <div className="row justify-content-md-center">
            



        <div className="row">
                    
                    
                 
                 

            
   
   
        <div className="row justify-content-md-center">
        <div style={{textAlign:"center", fontSize:"18pt"}}>
            
        <strong className={statusCode}>{statusText}</strong>
        <br />
        <br />

          </div>


          <div className="col-offset-3 col-lg-6 col-sm-12">
            <div className="row">
              <Form.Group
                className="col-lg-12 col-sm-12 mb-3"
                controlId="email"
              >
                <Form.Label>E-Mail</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>

            </div>

            <Button style={isFetching ? {display:"none"}:{}} variant="primary" onClick={onSubmit} type="button">
              Submit
            </Button>
          </div>


        </div>
        




          </div>


        </div>
        








      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>




    </div>
  );
}
