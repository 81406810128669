import logo from "./logo.svg";
import "./App.css";

import axios from "axios";
import Config from "./config";

import "bootstrap/dist/css/bootstrap.min.css";
import GitInfo from 'react-git-info/macro';

import RegisterPage from "./pages/RegisterPage";


//
import {
  Link,
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import config from "./config";
import LoginPage from "./pages/loginPage";
import UploadPage from "./pages/UploadPage";
import React, { useState } from "react";
import LogoutPage from "./pages/Logout";
import ActivationPage from "./pages/ActivationPage";
import Recover1Page from "./pages/Recover1Page";
import Recover2Page from "./pages/Recover2Page";
import ResendPage from "./pages/resendPage";


const gitInfo = GitInfo();


const customConf = require('./custom.js')


console.log(customConf);




config.apiClient = axios.create({
  baseURL: config.baseURL,
  timeout: 20000,
  withCredentials: true,
  

  
  headers: {
    "Content-Type": "multipart/form-data",
    'Access-Control-Allow-Origin': 'https://api.bagdiken.com/',
   'Token': localStorage.getItem('token')
    
            }
});


//  'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8,application/json',
   



function App() {


  const [loginStatus,setLoginStatus] = useState(false);
  const [headerHTML,setHeaderHTML] = useState("");
  const [afterHeaderHTML,setAfterHeaderHTML] = useState("");
  const [footerHTML,setFooterHTML] = useState("");

  

  React.useEffect(function(){
     
    if(localStorage.getItem('token') !== null && localStorage.getItem('token') != "undefined"){
      setLoginStatus(true);
    }

    setHeaderHTML(customConf.headerHTML)
    
    setAfterHeaderHTML(customConf.afterHeaderHTML)
    setFooterHTML(customConf.footerHTML)
  },[])


  return (
    <BrowserRouter>
      <div>




      <Navbar bg="dark" expand="lg">
          <Container>
            <Navbar.Brand style={{color:"#fff"}} href="#home">{headerHTML}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent: "flex-end"}}>
              <Nav className="me-right pull-right">
                

                {!loginStatus && <Nav.Link  style={{color:"#fff"}} href="/login">Login</Nav.Link>}
                {!loginStatus && <Nav.Link  style={{color:"#fff"}} href="/register">Register</Nav.Link>}
                
                
                
                {loginStatus && <Nav.Link  style={{color:"#fff"}} href="/upload">Upload Abstract</Nav.Link>}
                {loginStatus && <Nav.Link  style={{color:"#fff"}} href="/logout">Logout</Nav.Link>}
                


              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>


        {afterHeaderHTML}


        <br />
<br />
<br />


        


        <Container>

        
        <div>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/resend" element={<ResendPage />} />
            <Route path="/recovery" element={<Recover1Page />} />
            <Route path="/recovery/:selector/:token" element={<Recover2Page />} />
            <Route path="/activation/:selector/:token" element={<ActivationPage />} />
          </Routes>
        </div>
      </Container>
      </div>


    <div style={{textAlign:"center", marginTop:'150px', fontSize:"9pt"}}>
    
    version<br />{(new Date(gitInfo.commit.date)).toLocaleString()}
    {footerHTML}

    </div>

    </BrowserRouter>



  );
}

export default App;
