export const headerHTML = function() {
    
    
    return  <>
    <a href='https://conference.joinme2.org/' 
    class='navbar-brand' style={{color: "#ffffff"}}  >
   JoinMe2 Conference
    </a>
    </>
}

export const afterHeaderHTML = function() {
    
    
    return  <>
   
   <div class="" style={{backgroundColor:"#dddd", padding:"15px"}}>
        <div class="container">
        <div class="row">
        <div class="col-3">
        <a href="https://joinme2.org/" target="_blank">
            <img width="100px" src="https://joinme2.org/assets/images/logo.png" />
        </a>



        
        



          </div>
          <div class="col-9 d-flex align-items-center">
          <p>Welcome to the Joinme2 Conference Registration page!<br />
        
          <a href="/register">Click here for Registration</a>. If you are planning to present a paper, you can login after registration and upload your abstract from upload page.

        
        </p>

          </div>

          </div>
          </div>
          </div>


        

    </>
}


export const footerHTML = function() {
    
    return  <>
    <br />
    <div className="container text-center
    ">

            <em style={{fontSize:"8pt"}} > Developed by <a href="https://bagdiken.com" target="_blank">bagdiken</a> for <a href="https://joinme2.org" target="_blank">joinMe2</a>
            </em>
            </div>
            </>

}

