import { useState } from "react";
import { Button, Form } from "react-bootstrap";
//import { Form } from "react-router-dom";
import config from "../config";
import React from 'react';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function RegisterPage() {


  const [formData, setFormData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  var handleChange = (e) => {
    var tmp = {};
    console.log(e);
    console.log(config.baseURL);

    for (const [key, value] of Object.entries(formData)) {
      tmp[key] = value;
    }
    if (
      e.target.id == "at_campus" ||
      e.target.id == "present_paper"
    ) {
      e.target.value = e.target.checked;
    }

    //if(!Object.entries(tmp).includes(e.target.id)){
    tmp[e.target.id] = e.target.value;
    //}

    setFormData(tmp);
    console.log(tmp);




    //this.setState({[e.data.target]: e.data.value})
  }

  var onSubmit = () => {
    setIsFetching(true);
    toast.dismiss();
    toast.loading("Loading", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });


    //api.defaults.headers.post[''] = '*';
    config.apiClient
      .post('register/', formData)
      .then((response) => {

        toast.dismiss();
        console.log(response);
        if (response.data.status) {

          response.data.msg.forEach(row => {
            toast.success(row, {
              position: "bottom-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });


            setTimeout(function () {

             // window.history.pushState(null, null, "login")
             // window.location.reload(false);

            }, 2000)
          });


        } else {



          setIsFetching(false);
          toast.error(response.data.msg.join(", "), {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            enableHtml: true
          });



        }

      });



    console.log(formData);

    // console.log(this.state.request_data)   // you should be able to see your form data
  }

  return (
    <div>


      <Form>
        <div className="row">
          <div className="col-lg-9 col-sm-12">
            <div className="row">
              <Form.Group className="col-lg-4 col-sm-12 mb-3" controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>




              <Form.Group className="col-lg-4 col-sm-12 mb-3" controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>

              <Form.Group className="col-lg-4 col-sm-12 mb-3" controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>


            </div>


            <div className="row">
              <Form.Group onChange={handleChange} className="col-lg-6 col-sm-12 mb-3" controlId="affiliation">
                <Form.Label>Affiliation</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
              <Form.Group className="col-lg-6 col-sm-12 mb-3" controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control onChange={handleChange} as="textarea" rows={3} />
              </Form.Group>
            </div>
          </div>

          <div className="col-lg-3 col-sm-12">
            <div className="row">
              <Form.Group className="col-lg-12 col-sm-12 mb-3" controlId="email">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={handleChange} type="password" placeholder="Password" />
              </Form.Group>

              <div className="col-12">
                <Form.Group className="mb-3" controlId="present_paper">
                  <Form.Check onChange={handleChange} type="checkbox" label="I will present a paper" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="at_campus">
                  <Form.Check onChange={handleChange} type="checkbox" label="I attent on campus, not online " />
                </Form.Group>



              </div>
            </div>

            <Button style={isFetching ? { display: "none" } : {}} variant="primary" onClick={onSubmit} type="button">
              Submit
            </Button>
          </div>
        </div>
      </Form>


      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />




    </div>
  );
}
