import { useState } from "react";
import { Button, Form, Nav } from "react-bootstrap";
//import { Form } from "react-router-dom";
import config from "../config";
import React from 'react';


  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';



export default function LoginPage() {


  const [formData,setFormData] = useState({});
  const [isFetching,setIsFetching] = useState(false);
  const [showBody,setShowBody] = useState(false);



  



React.useEffect(function(){
  if(localStorage.getItem('token') !== null && localStorage.getItem('token') !== "undefined"){
    window.history.pushState(null, null, "upload")
    window.location.reload(false);
  }else{
    setShowBody(true);
  }
},[])


  var handleChange = (e) => {
    var tmp = {};
    console.log(e);
    console.log(config.baseURL);

    for (const [key, value] of Object.entries(formData)) {
      tmp[key] = value;
    }
    if(
      e.target.id==="at_campus" || 
      e.target.id==="present_paper"
      ){
      e.target.value = e.target.checked;
    }

    
      tmp[e.target.id] = e.target.value;
      

    setFormData(tmp);
    



   
    //this.setState({[e.data.target]: e.data.value})
   }

   var onSubmit = () => {
    setIsFetching(true);
    toast.dismiss();
    toast.loading ("Loading",{
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    
    //api.defaults.headers.post[''] = '*';
    config.apiClient
    .post('login/', formData)
    .then((response) => {
      
      toast.dismiss();
      
      setIsFetching(false);
        console.log(response);
      if(response.data.status){
        

        localStorage.setItem('token', response.data.token);

        window.history.pushState(null, null, "upload");
        
        window.location.reload(false);


        response.data.msg.forEach(row => {
          toast.success(row, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });  
        });
        

      }else{
    
        

          toast.error(response.data.msg.join(", "), {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          enableHtml: true
          });

        

      }
      
    });



    console.log(formData);

    
}
   
  return (
    <div>
   
   
      <Form>
        <div className="row justify-content-md-center" style={{display:showBody === true ? 'flex':'none'}}>
            

        <h1 style={{textAlign:"center"}}>Login</h1><br /><br /><br /><br />
          <div className="col-offset-3 col-lg-6 col-sm-12">
            <div className="row">
              <Form.Group
                className="col-lg-12 col-sm-12 mb-3"
                controlId="email"
              >
                <Form.Label>E-Mail Address</Form.Label>
                <Form.Control onChange={handleChange} type="text" placeholder="" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={handleChange} type="password" placeholder="Password" />
              </Form.Group>

              <div className="col-12">
                {/******
                <Form.Group className="mb-3" controlId="remember">
                  <Form.Check onChange={handleChange} type="checkbox" label="Remember me" />
                </Form.Group>*** */}



             

              </div>
            </div>

            <br />
<br />
<br />
            <div className="row">

              
<div className="col-8" style={{textAlign:"left"}}>


<Nav.Link href="/recovery">
  
     Did you forgot your password?
     
 </Nav.Link>

  
 <Nav.Link href="/resend">
  
     Resend Activation Mail 
     
 </Nav.Link>


 </div>




<div className="col-4" style={{textAlign:"right"}}>

  
            <Button style={isFetching ? {display:"none"}:{}} variant="primary" onClick={onSubmit} type="button">
              Login
            </Button>

            </div>

</div>

          </div>


        </div>
      </Form>








      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>




    </div>
  );
}
